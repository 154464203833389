import { initializeApp } from 'firebase/app'
import { getAuth, GoogleAuthProvider, signInWithPopup } from 'firebase/auth'

const config = JSON.parse(process.env.HOSTING_CONFIG)
const app = initializeApp(config)

const auth = getAuth(app)

const button = document.getElementById('button')

const checkStatus = async () => {
    const result = await signInWithPopup(auth, new GoogleAuthProvider())
    // const result = await getRedirectResult(auth)

    if (result) {
        const token = await auth.currentUser.getIdToken()
        document.cookie = `__session=${token};`
        document.location.href = '/index.html'
    } else {
        button.style.display = 'flex'
    }
}

const provider = new GoogleAuthProvider()

button.onclick = () => {
    // signInWithRedirect(auth, provider)
    checkStatus()
}

checkStatus()
